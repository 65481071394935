*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
  height: 100vh;
  background-color:#28283c ;
 
}
/* .lowerDiv{
  display: flex;
  height: 75vh;
  justify-content: space-between;
}
.lowerDiv-1{
  width: 10%;
  background-color: black;
}
.lowerDiv-2{
  width: 10%;
  background-color: black;
} */
.App-container{
  display: flex;
  margin: auto;
  width: 25%;
  padding-top: 2%;
  text-align: center;
}
h1{
  color: white;
  font-size: 3rem;
  font-family: 'Nunito Sans', sans-serif;
  margin-left: 5%;
}
.BBB-logo{
   height: 10rem;
} 

.App-container-Title{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

/* .form-section {
  max-width: 10px;
  margin: 0px auto;
  position: relative;
} */
#wirewheel-ppp{
  display: block;
  position: absolute;
  top: 0;
}
#custom-styles{
  background-color: white;
  position: absolute;
  top: 28%;
  left:18%;
  width: 65%;
}

/* Media queries for for pixel range for 420 to 700px */
@media screen and (min-width:420px) and (max-width:700px)
{
  .App-container{
    display: flex;
    margin: auto;
    width: 60%;
    padding-top: 2%;
    text-align: center;
  }
}


@media screen and (min-width:701px) and (max-width:920px)
{
  .App-container{
    display: flex;
    margin: auto;
    width: 38%;
    padding-top: 2%;
    text-align: center;
  }
 
}
@media screen and (min-width:321px) and (max-width:419px)
{
  .App-container{
    display: flex;
    margin: auto;
    width:75%;
    padding-top: 2%;
    text-align: center;
  }
 
}
@media screen and (min-width:200px) and (max-width:320px)
{
  .App-container{
    display: flex;
    margin: auto;
    width:95%;
    padding-top: 2%;
    text-align: center;
  }
  #custom-styles{
    background-color: white;
    position: absolute;
    top: 28%;
    left:18%;
    width: 70%;
  }
}
@media screen and (min-width:1600px) and (max-width:2500px)
{
  .App-container{
    display: flex;
    margin: auto;
    width:14.5%;
    padding-top: 2%;
    text-align: center;
  }
}